import React, { useEffect, useState } from 'react';
import css from './MarketplacePopup.module.css';
import { Button, FieldPhoneNumberInput, FieldTextInput, Form } from '../../../components';
import { Field, Form as FinalForm } from 'react-final-form';
import { addToFirestoreDoc } from '../../../util/firebase';
import Switch from '@mui/material/Switch';

const SwitchComponent = ({ input, label1, value1, label2, value2 }) => {
  const handleChange = event => input.onChange(input.value === value1 ? value2 : value1);
  return (
    <div className={css.switchWrapper}>
      <span
        className={`${css.labelLeft} ${
          input.value === value1 ? css.labelSelected : css.labelNotSelected
        }`}
      >
        {label1}
      </span>
      <Switch
        checked={input.value === value2}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'controlled' }}
        color="default"
      />
      <span
        className={`${css.labelRight} ${
          input.value === value2 ? css.labelSelected : css.labelNotSelected
        }`}
      >
        {label2}
      </span>
    </div>
  );
};

const generate8DigitId = () => {
  const timestamp = Date.now();
  const id = (timestamp % 100000000).toString().padStart(8, '0');
  return id;
};

const MarketplacePopup = () => {
  const [error, setError] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [ready, setReady] = useState(false);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const closeButton = document.getElementById('popup_close_button');
      document.getElementById('popup_wrapper').append(closeButton);
      closeButton.style.display = 'block';
    }
  }, []);
  const handleFormSubmit = values => {
    setInProgress(true);
    const documentId = generate8DigitId();
    return addToFirestoreDoc('marketplace-popup-list', documentId, {
      ...values,
      submitDate: new Date().toString(),
    })
      .then(resp => {
        setError(false);
        setInProgress(false);
        setReady(true);
      })
      .catch(e => {
        console.log(e);
        setError(true);
        setInProgress(false);
        setReady(false);
      });
  };

  return (
    <div className={css.wrapper} id="popup_wrapper">
      <p className={css.title}>Welcome to ModernBuilder</p>
      <p className={css.subtitle}>
        Help us by answering the questions below so we understand
        <br /> your preferences & current status
      </p>

      <FinalForm
        initialValues={{
          has_plot: 'have_a_plot_already',
          location: 'urban_location',
          funds: 'funds_in_place',
          design: 'have_a_design_in_mind',
          storey: 'single_storey',
          style: 'modern_style',
          manage: 'manage_build_alone',
          contact: 'want_you_to_contact_me',
        }}
        onSubmit={handleFormSubmit}
        render={fieldRenderProps => {
          const { handleSubmit, values } = fieldRenderProps;
          const submitDisabled = !values.name || !values.email || !values.phoneNumber;

          return (
            <Form onSubmit={handleSubmit}>
              <Field
                name="has_plot"
                label1="Have a plot already"
                label2="Need a plot"
                value1="have_a_plot_already"
                value2="need_a_plot"
                component={SwitchComponent}
              />

              <Field
                name="location"
                label1="Urban location"
                label2="Rural location"
                value1="urban_location"
                value2="rural_location"
                component={SwitchComponent}
              />

              <Field
                name="funds"
                label1="Funds in place"
                label2="Need a mortgage"
                value1="funds_in_place"
                value2="need_a_mortgage"
                component={SwitchComponent}
              />

              <Field
                name="design"
                label1="Have a design in mind"
                label2="Need inspiration"
                value1="have_a_design_in_mind"
                value2="need_inspiration"
                component={SwitchComponent}
              />

              <Field
                name="storey"
                label1="Single storey"
                label2="Two storey"
                value1="single_storey"
                value2="two_storey"
                component={SwitchComponent}
              />

              <Field
                name="style"
                label1="Modern style"
                label2="Traditional style"
                value1="modern_style"
                value2="traditional_style"
                component={SwitchComponent}
              />

              <Field
                name="manage"
                label1="Am confident to manage build alone"
                label2="Need project manager"
                value1="manage_build_alone"
                value2="need_projet_manager"
                component={SwitchComponent}
              />

              <Field
                name="contact"
                label1="Want you to contact me"
                label2="Just browsing at present"
                value1="want_you_to_contact_me"
                value2="just_browsing_at_present"
                component={SwitchComponent}
              />

              <div className={css.rowFieldsWrapper}>
                <FieldTextInput
                  className={css.field}
                  type="text"
                  id={'name'}
                  name="name"
                  label={'Name*'}
                  placeholder={'Enter your name'}
                />

                <FieldTextInput
                  className={css.field}
                  type="text"
                  id={'email'}
                  name="email"
                  label={'Email'}
                  placeholder={'Enter your email'}
                />
              </div>
              <FieldPhoneNumberInput
                className={css.field}
                name="phoneNumber"
                id={'phoneNumber'}
                label={'Phone number'}
                placeholder={'+442071234567'}
              />

              {error && <p className={css.error}>Woops! Something went wrong, please try again.</p>}

              <div className={css.buttonsWrapper}>
                <Button
                  className={css.cancel}
                  type="button"
                  onClick={() => document.getElementById('popup_close_button')?.click()}
                  disabled={submitDisabled || inProgress}
                >
                  Cancel
                </Button>
                <Button
                  className={css.submit}
                  type="submit"
                  inProgress={inProgress}
                  disabled={submitDisabled || ready}
                  ready={ready}
                >
                  Send
                </Button>
              </div>
            </Form>
          );
        }}
      />
    </div>
  );
};

export default MarketplacePopup;
