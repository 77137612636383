import React, { useEffect, useRef, useState } from 'react';
import close from './close.png';
import closeBlack from './closeBlack.png';
import css from './Popup.module.css';

const isMobile = typeof window !== 'undefined' && window.innerWidth < 1025;

const Popup = props => {
  const { content, delay = 0, closeStyle } = props;
  const [show, setShow] = useState(false);
  const [closeIcon, setCloseIcon] = useState(isMobile ? closeBlack : close);
  const contentRef = useRef(null);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleClickOutside = event => {
        if (contentRef.current && !contentRef.current.contains(event.target)) {
          setShow(false);
        }
      };
      setTimeout(() => setShow(true), delay);

      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, []);

  if (!show) return null;
  return (
    <div className={css.wrapper}>
      <div className={css.content} ref={contentRef}>
        <img
          src={closeIcon}
          id="popup_close_button"
          className={css.close}
          onClick={() => setShow(false)}
          onMouseEnter={() => setCloseIcon(closeBlack)}
          onMouseLeave={() => setCloseIcon(isMobile ? closeBlack : close)}
        />
        {content}
      </div>
    </div>
  );
};

export default Popup;
